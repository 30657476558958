import * as React from "react";
import Layout from "../../components/Layout";
import FAQAccord from "../../components/GFlush";
import GeneralTab from "../../components/HTabs";
import Service from "../../components/Services";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import ContentImg from "../../components/GSTTemp/ContentImg";
import Document from "../../components/Document";
import ImgContent from "../../components/GSTTemp/ImgContent";
import About from "../../components/About";
// import Hero from "../../components/Hero";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function AccRecServices() {
  /* Slider */
  // const AccRecSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 50,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Accounts Receivable Services`,
  //     content: `Advisory Service for Accounts Receivable Services`,
  //     image: "/imgs/business/sliders/producer-slider.png",
  //     alt_tag:
  //       "Best Online Advisory Service for Accounts Receivable Services in Hosur",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 10,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online Advisory Service for Accounts Receivable Services`,
  //     content: `Best Advisory Service for Accounts Receivable Services Platform`,
  //     image: "/imgs/business/sliders/subsidiary-slider.png",
  //     alt_tag:
  //       "Best Online Advisory Service for Accounts Receivable Services  near Bangalore",
  //   },

  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 40,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Advisory Service for Accounts Receivable Services in Hosur`,
  //     content: `One the top most success rated Advisory Service for Accounts Receivable Services  in Hosur`,
  //     image: "/imgs/business/dsc-pvt.png",
  //     alt_tag:
  //       "Best Online Advisory Service for Accounts Receivable Services in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Accounts Receivable Service`,
    buyBtnLink: `#pricing-buy`,
    price: `6999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/gst.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/itr_filing.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `MSME Registration`,
        icon: '/imgs/icons/enterprises.png',
        alt_tag: 'MSME Registration',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `Tax Consultancy Service `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };
  /* About Data Without Form*/
  const AccRecAboutData = {
    title: `Online Accounts Receivable Services In India`,
    sub_title: ``,
    paragraph: `Accounts receivable services assist the company in managing
    its core financial operations, which influence and generate
    the firm's cash flow. The organization's cash flow must be
    healthy if it is to maintain a robust and successful
    business. Faster payments are available within the company
    thanks to accounts receivable services.`,
    header: `An efficient accounts receivables method that ensures the business
     will receive payments within a specific time limit is necessary.`,
    points: [],
  };

  /* Img Content Component Data */
  const AccRecIcData = {
    id: '',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'What are billing and invoicing?',
    image: '/imgs/registration/ipr/td8.png',
    alt_tag: 'Billing and Invoicing',
    Paragraph: '',
    points: [
      {
        content: `Do your customers pay your bills on time? Are your clients
            delaying payments because your invoices are missing? Do you
            have a system in place to monitor when an invoice is
            received by a client?`,
        icon: true,
      },
      {
        content: `Let's figure out a solution and design
            a dependable workflow to ensure accurate and practical
            invoice submission to your clients.`,
        icon: true,
      },

      {
        content: `The sincerity of your cash flow depends on the executives'
            time and the accurate billing you provide to your client.`,
        icon: true,
      },
      {
        content: ` An invoice is a list of goods and services you send to a client
          or customer that includes the sums they are responsible for
          paying to your company.`,
        icon: true,
      },
    ],
  };
  /* Document Section Data */
  const AccRecDocData = {
    id: 'acc-rec-doc',
    heading: `Advantages of Accounts Receivable Services`,
    sub_heading: `Using accounts receivable services provides numerous
    advantages, including time, money, and resource savings.
    Working on accounting receivables in-house without the
    assistance of a professional team, on the other hand, can
    result in lower profits and additional work. Accounts
    Receivable Services can benefit your company in the following
    ways:`,
    doc_contents: [
      {
        col: `col-lg-4 col-md-6`,
        delay: '100',
        img: `/imgs/icons/it/account-receivable.png`,
        alt_tag: `Improve Accounts Receivable Procedure`,
        header: `Improve Accounts Receivable Procedure`,
        description: `Accounts receivable outsourcing enables you to collect
            payments quickly and efficiently. There is no delay in
            payment collection, customer-friendly modern e-billing.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '200',
        img: `/imgs/icons/it/save-time-money.png`,
        alt_tag: `Conserve Time and Money`,
        header: `Conserve Time and Money`,
        description: `You may need to invest both time and money in developing an
            accounts receivable department, employing people, and
            constructing infrastructure.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '300',
        img: `/imgs/icons/it/business-focus.png`,
        alt_tag: `Focus on Your Core Business`,
        header: `Focus on Your Core Business`,
        description: `By delegating your accounting services to a professional,
            you will be able to focus on critical business
            responsibilities, allowing your company to develop and
            expand.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '400',
        img: `/imgs/icons/it/customer-screening.png`,
        alt_tag: `Screening Customers Effectively`,
        header: `Screening Customers Effectively`,
        description: `Accountants are have the ability to identify the few
            consumers who have a good credit history and can thus pay
            you back on time.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '500',
        img: `/imgs/icons/it/quicker-collection.png`,
        alt_tag: `Quicker Collections`,
        header: `Quicker Collections`,
        description: `The longer your accounts are late by a month or year, the
            more money you will lose, which will directly effect your
            cash flow.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '600',
        img: `/imgs/icons/it/digital-transfer.png`,
        alt_tag: `Digital Transfer`,
        header: `Digital Transfer`,
        description: `Because digital transfers payment options allow your clients
            to select their preferred way of payment and pay before the
            due date.`,
      },
    ],
  };
  /*  Content Img Component Data */
  const AccRecCiData = {
    id: 'role',
    background: 'main-content',
    mt_div: 'mt-3',
    header: 'Accounts of Customers Reconciliation',
    paragraph: ``,
    points: [
      {
        content: `Do you regularly monitor your accounts receivable to
      ensure that your clients pay you on time? Are any of your
      customers phoning about late fees, short payments, etc.?`,
        icon: false,
      },
      {
        content: `We ought to take extra precautions to keep financial
      issues at arm's length and periodically reconcile our
      clients.`,
        icon: false,
      },
      {
        content: `To prevent payment delays, we give the client an account
      statement on a sporadic basis.`,
        icon: false,
      },
      {
        content: `The accounts are updated
      through our focused working closely with the client's
      point of contact.`,
        icon: false,
      },
    ],
    image: '/imgs/registration/iec/iec-benefits.png',
    alt_tag: 'Accounts of Customers Reconciliation',
  };
  /* Service Data */
  const AccountData = {
    header: `TODAYFILINGS Accounts Receivable Services`,
    content: `We offers some of account receivable services for our
    customers what they need for their requirements, and the
    services are,`,
    image: '/imgs/account-receivable.png',
    alt_img: 'TODAYFILINGS Account Receivable Service',
    points: [
      `Billing and invoicing`,
      `Payment follow-ups`,
      `Verification of invoice receipts`,
      `Payment and invoice reconciliation`,
      `Credit Memo processing`,
      `Sales orders processing`,
    ],
  };
  // tabData
  const AccountTabsData = {
    id: 'features',
    heading: 'Account Receivable Services Process',
    paragraph: `The stages involved in account receivable services may fluctuate
    depending on the size of the company. On the one hand, larger
    organizations have more income and, as a result, they do not
    hesitate to invest in highly competent IT systems and credit
    management systems.`,
    body: [
      {
        tab_title: 'Credit Procedures',
        tab_content: [
          {
            content_title: `Established Credit Procedures`,
            content_paragraph: ``,
            points: [
              `The organization needs to create a credit application
            process. It will decide whether or not to give items on
            credit based on the applicant's creditworthiness. It is up
            to the company to decide whether or not to extend credit
            to individual customers or other businesses.`,

              `Furthermore, the company will develop a few terms and
             conditions for credit transactions that must adhere to 
             Federal regulations when using a loan. In addition, the 
             paper must include the customers' requirements and 
             obligations. For example, the firm must inform its clients
             about credit interest rates.`,
            ],
          },
        ],
        content_img: '/imgs/assited/credit.png',
        alt_img: 'Best Account Receivable Service in Hosur',
      },
      {
        tab_title: 'Invoicing and Billing',
        tab_content: [
          {
            content_title: 'Customer Invoicing and Billing',
            content_paragraph: ``,
            points: [
              `A
            receipt is a document that a customer receives after
            purchasing specified products or services. The receipt
            contains information such as the cost of the
            items/services rendered, the specifics of the same, and
            the payment date.`,
              `Every invoice has a unique invoice number that may be
            easily retrieved. They are provided in either physical or
            electronic form, depending on the customer's preference.
            Most businesses prefer electronic invoices because they
            are more cost-effective and convenient.`,
            ],
          },
        ],
        content_img: '/imgs/assited/invoice-billing.png',
        alt_img: 'Best Account Receivable Service near Bangalore',
      },
      {
        tab_title: 'Monitoring',
        tab_content: [
          {
            content_title: 'Account Receivables should be monitored',
            content_paragraph: ``,
            points: [
              `When you have done invoicing, the following step is to
            track accounts receivable with the assistance of an
            Accounts Receivables Officer. The officer will review the
            payment that was deposited into the supplier's bank
            account, enter it into the AR system, and assign it to an
            invoice.`,
              `The officer is in charge of reconciling the AR ledger to
            ensure that payments are correctly posted. Furthermore, it
            sends monthly statements to its customers, which include
            information on the amounts outstanding.`,
            ],
          },
        ],
        content_img: '/imgs/assited/acc-rec-monitoring.png',
        alt_img: 'Best Account Receivable Monitoring Service in Hosur',
      },
      {
        tab_title: 'Accounting',
        tab_content: [
          {
            content_title: 'Accounting for Account Receivable',
            content_paragraph: `The Collection Officer establishes the due date for both
          bad and outstanding debts. Following the inspection of the
          unpaid bills by the official, the accounting department
          would create journal entries to document the
          transactions.Furthermore, the accounts department is at
          risk for validating early reductions made.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/accounting-reci-acc.png',
        alt_img: 'Best Account Receivable Service in Hosur',
      },
    ],
  };
  /* FAQ data */
  var FAQ_data = [
    {
      header: 'Is accounts receivable stressful job?',
      body: [
        {
          content: `The accounts receivable job description can be rather stressful,
          as it requires handling accounts and big sums of money on a daily
          basis.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'What are account receivable job duties?',
      body: [
        {
          content: `TAn Accounts Receivable employee's primary responsibility is to
          guarantee that their organization receives payments for goods and
          services and that these transactions are properly recorded.`,
          icon: false,
        },
      ],
    },

    // 3
    {
      header: 'Which of the following are the five internal controls?',
      body: [
        {
          content: `The internal control framework has five components:`,
          icon: false,
        },
        {
          content: `Control environment,`,
          icon: true,
        },
        {
          content: `Risk Analysis,`,
          icon: true,
        },
        {
          content: `Control techniques,`,
          icon: true,
        },
        {
          content: `Information and Communication,`,
          icon: true,
        },
        {
          content: `Monitoring.`,
          icon: true,
        },
      ],
    },
    // 4
    {
      header:
        ' What is the distinction between accounts payable and receivable?',
      body: [
        {
          content: `The distinction between accounts receivable and accounts payable
          is that accounts receivable are monies owing to a company by its
          customers, whereas accounts payable are amounts owed to the
          company.`,
          icon: false,
        },
      ],
    },
    // 5
    {
      header: 'What factors contribute to a decrease in accounts receivable?',
      body: [
        {
          content: `Changes in the company's credit policy and rising difficulties in
          collecting receivables on schedule are the two main causes of a
          decreasing ratio.`,
          icon: false,
        },
      ],
    },
  ];

  return (
    <div>
      <Seo
        title='Accounts Receivable Services'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={AccRecSlider} /> */}

          <Package packageData={PackageCompData} />

          <About about_data={AccRecAboutData} />

          <Counter />

          <ImgContent item={AccRecIcData} />

          <Service item={AccountData} />

          <Document docSecData={AccRecDocData} />

          <GeneralTab HTabsData={AccountTabsData} />

          <ContentImg CISection_data={AccRecCiData} />
          <section style={{ marginTop: 30 }}></section>
          <Cta />

          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
